import React from 'react';
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Isi from '@components/content/isi/Isi';
import Hero from '@components/content/hero/Hero';
import './styles.scss';

const IsiPage = () => {
	return (
		<Layout>
			<Seo title='Important Safety Information | EMFLAZA® (deflazacort)' description='View Important Safety Information for EMFLAZA® (deflazacort). See full Prescribing Information.' />
			<div className='page--isi'>
				<Hero
					heroImage='hero-isi.png'
					heroImageMobile='hero-isi-mobile.png'
					title={<>Important Safety Information for EMFLAZA<sup className='text-4xl md:text-7xl -top-1'>&reg;</sup> <span className='lowercase'>(deflazacort)</span></>}
					useBreadcrumbs={false}
				/>
				<section className='pb-9 md:pb-32'>
					<div className='container'>
						<Isi />
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default IsiPage;
