import React from 'react';
import PropTypes from "prop-types"
import { KfAnchorLink } from '@klickinc/kf-react-components';
import './styles.scss';

const ISI = ({isFullIsiShown}) => {
	return (
		<div className='isi'>
			{ isFullIsiShown &&
				<div>
					<h3 className='mb-5 text-base font-bold uppercase font-roboto'>Indication & Important Safety Information for <br /> EMFLAZA<sup>&reg;</sup> <span className='lowercase'>(deflazacort)</span></h3>
					<div className='isi__indication mb-3'>
						<h3 className='mb-2 font-bold uppercase font-roboto'>Indication</h3>
						<p className="font-normal">EMFLAZA<sup>&reg;</sup> is a corticosteroid indicated for the treatment of Duchenne muscular dystrophy (DMD) in patients 2 years of age and older.</p>
					</div>
				</div>
			}
			<div className='isi__safety-information'>
				{ isFullIsiShown &&
					<h3 className='mb-2 font-bold uppercase font-roboto'>Important Safety Information</h3>
				}
				<p className="font-normal lg:hidden"><strong>Contraindications:</strong> EMFLAZA{!isFullIsiShown && <span><sup>&reg;</sup> (deflazacort) </span> } is contraindicated in patients with a hypersensitivity to deflazacort or any of the inactive ingredients in EMFLAZA.</p>
				<p className="hidden font-normal lg:block"><strong>Contraindications:</strong> EMFLAZA is contraindicated in patients with a hypersensitivity to deflazacort or any of the inactive ingredients in EMFLAZA.</p>
				<h4 className='my-2 font-bold font-roboto'>Warnings & Precautions</h4>
				<ul className='bulleted-list'>
					<li><strong>Alterations in Endocrine Function:</strong> Corticosteroids, such as EMFLAZA, can cause serious and life-threatening alterations in endocrine function, especially with chronic use. Monitor patients receiving EMFLAZA for Cushing’s syndrome, hyperglycemia, and adrenal insufficiency after EMFLAZA withdrawal. In addition, patients with hypopituitarism, primary adrenal insufficiency or congenital adrenal hyperplasia, altered thyroid function, or pheochromocytoma may be at increased risk for adverse endocrine events. Acute adrenal insufficiency or “withdrawal syndrome” can occur if corticosteroids are withdrawn abruptly, and can be fatal. The risk is reduced by gradually tapering the corticosteroid dose when withdrawing treatment. During times of medical stress, corticosteroid dosage may need to be increased.</li>
					<li><strong>Immunosuppression and Increased Risk of Infection:</strong> Increased risk of new, exacerbation, dissemination, or reactivation of latent infections, which can be severe and at times fatal; Signs and symptoms of infection may be masked. Tell patients and/or caregivers to inform their healthcare provider if the patient has had recent or ongoing infections or if they have recently received a vaccine. Warn patients who are on corticosteroids who have not had chickenpox or measles to avoid exposure to chickenpox or measles and to alert their healthcare provider immediately if they are exposed.</li>
					<li><strong>Alterations in Cardiovascular/Renal Function:</strong> Monitor for elevated blood pressure. Dietary salt restriction and potassium supplementation may be needed.</li>
					<li><strong>Gastrointestinal Perforation:</strong> Increased risk of gastrointestinal perforation during corticosteroid use in patients with certain gastrointestinal disorders such as active or latent peptic ulcers, diverticulitis, recent intestinal anastomoses, and inflammatory bowel disease. Signs and symptoms may be masked.</li>
					<li><strong>Behavioral and Mood Disturbances:</strong> May include euphoria, insomnia, mood swings, personality changes, severe depression, and psychosis. Encourage patients to seek medical attention if symptoms develop or worsen.</li>
					<li><strong>Effects on Bones:</strong> The risk of osteoporosis increases with prolonged use of EMFLAZA, which can predispose patients to vertebral and long bone fractures. Monitor for decreases in bone density with chronic use of EMFLAZA.</li>
					<li><strong>Ophthalmic Effects:</strong> May include cataract formation, ocular infections, and glaucoma. If treatment with corticosteroids, including EMFLAZA, are continued for more than 6 weeks, monitor intraocular pressure.</li>
					<li><strong>Vaccination:</strong> Do not administer live or live attenuated vaccines to patients receiving immunosuppressive doses of corticosteroids. Administer live-attenuated or live vaccines at least 4 to 6 weeks prior to starting EMFLAZA.</li>
					<li><strong>Serious Skin Rashes:</strong> Toxic epidermal necrolysis has been reported with the use of deflazacort. Discontinue at the first sign of rash, unless the rash is clearly not drug related.</li>
					<li><strong>Effects on Growth and Development:</strong> Long-term use of corticosteroids, including EMFLAZA, may slow growth and development in children.</li>
					<li><strong>Thromboembolic Events:</strong> Observational studies have shown an increased risk of thromboembolism. Use EMFLAZA with caution in patients who have or may be predisposed to thromboembolic disorders.</li>
				</ul>
				<p className="my-3">
					<strong>Adverse Reactions:</strong> The most common adverse reactions (≥10% for EMFLAZA and greater than placebo) are Cushingoid appearance, weight increased, increased appetite, upper respiratory tract infection, cough, pollakiuria, hirsutism, central obesity, and nasopharyngitis.
				</p>
				<p className="my-3">
					<strong>Drug Interactions:</strong> Give one third of the recommended dose of EMFLAZA when EMFLAZA is administered with strong or moderate CYP3A4 inhibitors. Avoid use of strong or moderate CYP3A4 inducers with EMFLAZA, as they may reduce efficacy.
				</p>
				<p className='my-3 prescribing-detail'>
					<strong>Please see <KfAnchorLink
							url='https://www.emflaza.com'
							target="_blank"
							linkClass='link link--light-blue'
							relationship='noopener'
						>
							www.emflaza.com
						</KfAnchorLink> for the
						full Prescribing Information.
					</strong>
				</p>
				<p>For medical information, product complaints, or to report an adverse event, please call <KfAnchorLink url='tel:8665624620' target="_blank" linkClass='link link--light-blue'>1&#8209;866&#8209;562&#8209;4620</KfAnchorLink> or email at <KfAnchorLink url="mailto:usmedinfo@ptcbio.com" target="_blank" linkClass='link link--light-blue'>usmedinfo@ptcbio.com</KfAnchorLink>.</p>
				<p className='mt-3'>You may also report adverse events directly to FDA at <KfAnchorLink url='tel:8003321088' target="_blank" linkClass='link link--light-blue'>1&#8209;800&#8209;FDA&#8209;1088</KfAnchorLink> or <KfAnchorLink url='https://www.fda.gov/medwatch' relationship='noopener' target="_blank" linkClass='link link--light-blue'>www.fda.gov/medwatch</KfAnchorLink>.</p>
			</div>
		</div>
	);
};

export default ISI;


ISI.defaultProps = {
	isFullIsiShown: true,
};


ISI.propTypes = {
    isFullIsiShown: PropTypes.bool,
}
